import React, { ReactNode } from 'react';
import styled from 'styled-components';

import LinkHere from '../../components/LinkHere'

interface OptionsTextBlockProps {
    heading: string;
    paragraph: string;
    onClick: () => void;
    children?: ReactNode;
}

const OptionsContentDiv = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
`;

const OptionsHeading = styled.h1`
    font-family: 'Arial-MT-Bold';
    font-size: 2rem;
    padding: .4rem 1rem .4rem 1rem;
    white-space: pre-wrap;
    line-height: 100%;
    border-left: 5px solid #FEDA00;
`;


const OptionsParagraph = styled.p`
    font-family: 'Roboto-Thin';
    font-size: 1rem;
    line-height: 170%;
    letter-spacing: 0.05rem;

    @media (min-width: 1921px) {
      font-size: 1rem;
      text-align: left;
    }
`;


const PortfolioOptionTextBlock: React.FC<OptionsTextBlockProps> = ({ heading, paragraph, children, onClick}) => {

    return(
        <OptionsContentDiv>
            <OptionsHeading>{heading}</OptionsHeading>
            <OptionsParagraph>{paragraph}</OptionsParagraph>
            <LinkHere onClick={onClick}>{children}</LinkHere>
        </OptionsContentDiv>
    );
}

export default PortfolioOptionTextBlock;

import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';


import Menu from './views/components/menu/Menu';

import { ScrollProvider } from './context/scrolltocontext';

import { ResizeProvider } from './context/resizecontext';

import { LightboxProvider } from './views/components/lightbox/Lightbox';

import Home from './views/routes/Home';

import Footer from './views/components/footer/Footer';


const App = () => {

  return (
    
      <BrowserRouter>
      <ResizeProvider>
        <ScrollProvider> 
          <Menu />
          <LightboxProvider> 
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
          </LightboxProvider> 
        </ScrollProvider>
          <Footer />
        </ResizeProvider>
      </BrowserRouter>
   
  );
};

export default App;

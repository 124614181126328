import React from 'react';
import styled from 'styled-components';

import { useScroll } from '../../../context/scrolltocontext';

import homebg from '../../assets/images/space-bg2.jpg';

import PortfolioOptionTextBlock from './PortfolioOptionTextBlock';

const PortfolioOptionsContainer = styled.div`
    box-sizing: border-box;
    width: 90%;
    max-width: 1500px;
    display: grid; 
    gap: 10vw;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    border: 3px solid #FEDA00;
    border-radius: 60px;
    margin-top: -150px;
    padding: 50px 40px 40px 40px;
    background-image: linear-gradient(290deg, rgba(76,37,155,0.7) 20%, rgba(56,56,56,0) 90%), url(${homebg});
    background-repeat: no-repeat;
    background-attachment: scroll, scroll;
    background-position: top, bottom;
    background-size: cover, cover;
    transitions: all 500ms ease-out;
    margin-bottom: 40px;

    @media (min-width: 1000px){
        background-position: top, left center;
        background-size: cover, cover;
        grid-template-columns: 1fr 1fr;
        padding: 60px 100px;
        gap: 5vw;
        width: 100%;
        max-width: 1500px;
    }
`;

const PortfolioOptions = () => {

    const { designPortfolioRef, devPortfolioRef, scrollToSection } = useScroll();


    return (
        <PortfolioOptionsContainer>
            <PortfolioOptionTextBlock
                heading="Keep it simple,but not boring."
                paragraph="I enjoy combining typography and graphics to create eye catching and informative marketing related design material. Web or Print I have experience designing everything from product sell sheets to cnc engravings."
                onClick={() => scrollToSection(designPortfolioRef)}
            >view design</PortfolioOptionTextBlock>
            <PortfolioOptionTextBlock
                heading="HTML, Javascript, Node, Git, M.E.A.N, React, Bootstrap, PHP, SQL, ASP.net, oh my..."
                paragraph="Websites today are complicated applications with many parts. I’ve invested the time to build a solid understanding of these different technologies, frameworks, packages, modules, API and more... "
                onClick={() => scrollToSection(devPortfolioRef)}
            >view dev</PortfolioOptionTextBlock>
        </PortfolioOptionsContainer>
    );

};


export default PortfolioOptions;
import React, { useState, useRef, useLayoutEffect } from 'react';
import styled from 'styled-components';

import { gsap } from 'gsap';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { useScroll } from '../../../context/scrolltocontext';

import LinkHere from '../../components/LinkHere'

import markLogoImg from '../../assets/images/mark-logo.svg';

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);
  
const FontFaceObserver = require('font-face-observer');

const HomeContentDiv = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    height: fit-content;
    align-items: center;
    justify-content: center;
    gap: 3.75vh;
    @media (min-width: 1100px){
      align-items: start;
    }

`;

const HomeHeading = styled.div`
    font-family: 'Arial-MT-Bold';
    font-size: clamp(3.5rem, 14vw, 15rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 90%;
    margin-left: -10px;
    letter-spacing: -0.15rem;
    margin-bottom: 4vh;

    @media (min-width: 1100px){
      font-size: clamp(3.5rem, 7vw, 8.5rem);
      align-items: start;
      margin-bottom: 2vh;
    }
`;
const HomeParagraph = styled.p`
    font-family: 'Poppins-ExtraLight';
    font-size: 4vw;
    line-height: 150%;
    letter-spacing: 0.05rem;
    text-align: center;
    margin-bottom: 4vh;
    margin-top: 3vh;
  

    @media (min-width: 1100px) {
      font-size: 1rem;
      text-align: left;
      line-height: 180%;
      max-width: 70%;

    }

    @media (min-width: 1921px) {
      font-size: 1.05rem;
      text-align: left;
      line-height: 180%;
    }
`;

const MarkLogo = styled.img`
    display: none;
    width: clamp(100px, 8vw, 200px);
    height: auto;
    align-self: flex-start;

    @media (min-width: 1100px) {
      display: block;
    }
`;


interface HomeTextProps {
    onFontsLoaded: () => void;
}

const HomeText: React.FC<HomeTextProps> = (({ onFontsLoaded }) => {

    const { aboutRef, scrollToSection } = useScroll();

    const [fontLoaded, setFontLoaded] = useState(false);
    
    let localRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        const observer = new FontFaceObserver('Arial-MT-Bold');
        observer.check().then(() => {
            setFontLoaded(true);
            onFontsLoaded();
        })
    }, [onFontsLoaded]);

    // useLayoutEffect(() => {
    //     if (localRef.current) {
    //       const tl = gsap.timeline({
    //         repeat: 0,
    //         delay: 1,
           
    //       });
      
    //       const spans = localRef.current.querySelectorAll('span');
      
    //       if (spans.length) {
    //         tl.fromTo(spans, {
    //           x: -10,
    //         },
    //         {
    //           x: 0,
    //           duration: 0.15, // Animation duration for each span
    //           stagger: 0.15, // Delay between each span's animation
    //         }
    //     );
    //       }
    //     }
    // }, []);

    return  (
        <HomeContentDiv className="container">
            <MarkLogo src={markLogoImg}/> 
            <div>
            <HomeHeading ref={localRef}>
                <span>frontend</span>
                <span>developer</span>
                <span>designer</span>
            </HomeHeading>
            <HomeParagraph>Graphic design and web development I have experience doing a wide range of projects. Take a moment to browse my portfolio.</HomeParagraph>
            </div>   
            <LinkHere onClick={() => scrollToSection(aboutRef)}>learn more</LinkHere>
        </HomeContentDiv>
        );
    });

export default HomeText;
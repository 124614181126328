import React, { createContext, useState, useEffect, useContext } from "react";

type ResizeContextType = {
  width: number;
  height: number;
};

const ResizeContext = createContext<ResizeContextType | undefined>(undefined);

export const ResizeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [size, setSize] = useState<ResizeContextType>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return <ResizeContext.Provider value={size}>{children}</ResizeContext.Provider>;
};

export const useResize = (): ResizeContextType => {
  const context = useContext(ResizeContext);
  if (!context) {
    throw new Error("useResize must be used within a ResizeProvider");
  }
  return context;
};
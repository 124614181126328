import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';

import styled from 'styled-components';

const FormContainer = styled.div`
box-sizing: border-box;
padding: 20px;
width: 100%;
`;

const Form = styled.form`
    font-family: 'Rubik';
`;

const FormError = styled.small`
    color: red;
`;

const FormState = styled.p`
    font-family: "Poppins-Light";
    font-size: 1.25rem;
`;

const FormInputContainer = styled.div`
    margin-bottom: 10px;
`;

const FormInput = styled.input`
box-sizing: border-box;
    font-family: "Poppins-Light";
    font-size: 1.25rem;
    background-color: #33333320;
    border: 1px solid #ffffff20;
    border-bottom: 1px solid #ffffff50;
    color: #fff;
    width: 100%;
    padding: 8px;
    marginTop: 4px;

    &::placeholder {
        color: #fff;
    }

        &:focus {
        background-color: #ffffff10;
    }
`;

const FormMessageInput = styled.textarea`
box-sizing: border-box;
    font-family: "Poppins-Light";
    font-size: 1rem;
    border: 1px solid #ffffff20;
    border-bottom: 1px solid #ffffff50;
    outline none;
    background-color: transparent;
    width: 100%;
    padding: 8px;
    marginTop: 4px;
    height: 100px;
    color: #fff;
    background-color: #33333320;

    &::placeholder {
    color: #fff;
    }

    &:focus {
        background-color: #ffffff10;
    }
`;

const FormButton = styled.button`
    font-family: "Bebas-Regular";
    text-transform: uppercase;
    font-size: 1.05rem;
    background-color: #feda00;
    border: 1px solid #feda00;
    border-radius: 5px;
    padding: 5px 30px;
    color: #333;
    width: 100%;
    cursor: pointer;

    &:hover {
        background-color: #FFF; 
        color: #333;
    };
`;

export type ContactFormRef = {
  clearForm: () => void;
};

interface EmailFormProps {
  onMessageSuccess: () => void; // Callback function to handle success message
}


const MessageForm: React.FC<EmailFormProps> = ({onMessageSuccess}) => {

  const form = useRef<HTMLFormElement | null>(null);

  const [formState, setFormState] = useState(false);

  const sendEmail = (e: React.FormEvent) => {
    e.preventDefault();

    if (form.current && validateForm()) {
      setFormState(true);
    emailjs
      .sendForm('service_v349bec', 'template_8mipqux', form.current, {
        publicKey: 'G08zvZguYmKJ7X5rA',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          setFormState(true);
          onMessageSuccess();
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
    };
  };

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Clear errors as the user types
    setErrors({
      ...errors,
      [name]: '',
    });
  };

  const validateForm = () => {
    const newErrors = {
      name: '',
      email: '',
      message: '',
    };

    if (!formData.name) newErrors.name = 'Name is required.';
    if (!formData.email) {
      newErrors.email = 'Email is required.';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Enter a valid email address.';
    }
    if (!formData.message) newErrors.message = 'Message is required.';

    setErrors(newErrors);

    return !Object.values(newErrors).some((error) => error);
  };

  return (
    <FormContainer>
      {!formState ? ( 
      <Form ref={form} onSubmit={sendEmail}>
        <FormInputContainer>
          <FormInput
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Name"
          />
          {errors.name && <FormError>{errors.name}</FormError>}
        </FormInputContainer>
        <FormInputContainer>
          <FormInput
            type="email"
            id="email"
            name="email"
            placeholder="E-mail"
            value={formData.email}
            onChange={handleChange}
          />
          {errors.email && <FormError>{errors.email}</FormError>}
        </FormInputContainer>
        <FormInputContainer>
          <FormMessageInput
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            placeholder="What's the idea?"
          ></FormMessageInput>
          {errors.message && <FormError>{errors.message}</FormError>}
        </FormInputContainer>
        <FormButton type="submit">
          Send Message
        </FormButton>
      </Form>
      ) : <FormState>Success! Message sent.</FormState>}
    </FormContainer>
  );
};

export default MessageForm;
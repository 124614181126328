import React from "react";
import styled from 'styled-components';

import blackbg from '../../assets/images/black_pattern.png';

const FooterContainer = styled.footer`
position: relative;
box-sizing: border-box;
min-width: 100%;
height: 50px;
display: flex;
align-items: center;
justify-content: center;
padding: 20px;
background-image: linear-gradient(300deg, rgba(56,56,56,0) 0%, rgba(44,44,44,1) 80%), url(${blackbg});
`;

const FooterInnerContainer = styled.section`
`;

const NameLogo = styled.span`
    font-family: 'Rubik';
    font-size: 0.8rem;
    text-transform: uppercase;
    cursor: pointer;
`;

const Footer = () => {
    return (
        <FooterContainer>
            <FooterInnerContainer>
            <NameLogo>Mark McGuigan © 2025</NameLogo>
            </FooterInnerContainer>
        </FooterContainer>
    );
}

export default Footer;
import React, { useRef, useEffect } from 'react';

import { gsap } from 'gsap';

import { useResize } from '../../context/resizecontext';

import styled from 'styled-components';

import adobecc from '../assets/images/devicons/adobecc.svg';
import bootstrap from '../assets/images/devicons/bootstrap.svg';
import css from '../assets/images/devicons/css.svg';
import git from '../assets/images/devicons/git.svg';
import js from '../assets/images/devicons/js.svg';
import mongo from '../assets/images/devicons/mongo.svg';
import next from '../assets/images/devicons/next.svg';
import node from '../assets/images/devicons/node.svg';
import php from '../assets/images/devicons/php.svg';
import react from '../assets/images/devicons/react.svg';
import tailwind from '../assets/images/devicons/tailwind.svg'


const DevIconContainer = styled.section`
    box-sizing: border-box;
    width: fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: clamp(20px, 2vw, 50px);

`;

const DevIconsContainerOutter = styled.div`
    box-sizing: border-box;
    width: 100%;
    max-width: 1500px;
    overflow-x: hidden;
    border: 0px solid #ffffff80;
    padding: 24px 15px;
    border-radius: 60px;
`

const DevIconIMG = styled.img`
    max-width: 100px;
    width: 2%;
    min-width: 80px;
    height: auto;

    &:hover {
        background: #ffffff14;
    }
`;


const DevIcons: React.FC = () => {

    const { width, height } = useResize();
    
    const wrapperRef = useRef<HTMLDivElement>(null);
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const animationRef = useRef<GSAPTween | null>(null);

    useEffect(() => {
        if (!wrapperRef.current || !scrollContainerRef.current) return;
    
        const wrapperWidth = wrapperRef.current.offsetWidth;
        const scrollableWidth = scrollContainerRef.current.scrollWidth;

            // Kill the previous animation if it exists
        if (animationRef.current) {
          animationRef.current.kill();
        }

        gsap.set(scrollContainerRef.current, { x: 0 });

        animationRef.current = gsap.to(scrollContainerRef.current, {
          x: wrapperWidth - scrollableWidth, // Move content to the left
          duration: 30, // Control the speed of movement
          ease: "linear",
          repeat: -1, // Infinite looping
          yoyo: true,
          modifiers: {
            x: (x) => {
              const value = parseFloat(x);
              return value < 0 ? `${value}px` : "0px"; // Only allow negative values
            },
          },
        });


      return () => {
        // Cleanup Draggable and animation
        if (animationRef.current) animationRef.current.kill();
      };
    }, [width, height]);

    return (
        <DevIconsContainerOutter ref={wrapperRef}>
        <DevIconContainer ref={scrollContainerRef}>
            <DevIconIMG src={adobecc} alt="adobect" />
            <DevIconIMG src={bootstrap} alt="bootstrapt" />
            <DevIconIMG src={css} alt="css" />
            <DevIconIMG src={git} alt="git" />
            <DevIconIMG src={js} alt="js" />
            <DevIconIMG src={mongo} alt="mongo" />
            <DevIconIMG src={next} alt="next" />
            <DevIconIMG src={node} alt="node" />
            <DevIconIMG src={php} alt="php" />
            <DevIconIMG src={react} alt="react" />
            <DevIconIMG src={tailwind} alt="tailwindt" />
        </DevIconContainer>
        </DevIconsContainerOutter>
    );

};


export default DevIcons;
import React from 'react';
import styled from 'styled-components';


type PortfolioFilterButtonProps = {
    $selected: boolean;
    onClick: () => void;
    children: React.ReactNode;
}

const PortfolioFilterButton = styled.button<PortfolioFilterButtonProps>`
  position: relative;
  border-radius: 25px;
  border: none;
  box-shadow: 3px 3px 10px #15151569;
  background: linear-gradient(65deg, rgb(38, 38, 38), rgb(53, 53, 53)); /* Background as part of the button itself */
  width: 100%;
  font-family: 'Roboto-Regular';
  font-size: 0.9rem;
  padding: 10px 0px;
  letter-spacing: 0.06rem;
  color: ${({ $selected }) => ($selected ? 'rgb(255, 217, 0)' : '#ffffff')};
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease;
  animation: ${({ $selected }) => ($selected ? 'pulse 0.3s 1' : 'none')};
  border: ${({ $selected }) => ($selected ? '2px solid #ababab40' : '2px solid #ababab10')};

  @media (min-width: 1000px) {
    flex-direction: row;
    max-width: 200px;
  }

  &:hover {
    background: ${({ $selected }) =>
      $selected
        ? 'linear-gradient(65deg, rgb(38, 38, 38), rgb(53, 53, 53))'
        : 'linear-gradient(65deg, rgb(38, 38, 38), rgb(46, 46, 46))'};
    border: ${({ $selected }) =>
      $selected
        ? '2px solid #ababab40'
        : '2px solid #ababab80'};
  }

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${({ $selected }) =>
      $selected
        ? 'linear-gradient(90deg, #ff00ff, #6f00ff, #00aaff, #ff00ff, #6f00ff, #00aaff)'
        : 'none'};
    background-size: 250%;
    background-repeat: no-repeat;
    border-radius: 25px;
    margin: -5px;
    filter: ${({ $selected }) =>
      $selected
        ? 'blur(15px)'
        : 'blur(0px)'};
    opacity: ${({ $selected }) =>
      $selected
        ? '1'
        : '0'};
    animation: gradientAnimationB 5s linear infinite;
    transition: all 500ms ease-out;
  }
`;

const PortfolioFilterButtonComponent: React.FC<PortfolioFilterButtonProps> = ({$selected, onClick, children}) => {

    return (
        <PortfolioFilterButton $selected={$selected} onClick={onClick}>
            {children}
        </PortfolioFilterButton>
    );
}

export default PortfolioFilterButtonComponent;
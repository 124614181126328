import React, { useEffect, useRef, useState} from 'react';

import styled from 'styled-components';

import { useScroll } from '../../../context/scrolltocontext';

import { gsap } from 'gsap';

import rocketman from '../../assets/images/spaceman.png';

import homebg from '../../assets/images/bg.webp';

import MessageForm from './MessageForm';

import blackbg from '../../assets/images/black_pattern.png';

const HomeContactContainer = styled.section`
    box-sizing: border-box;
    position: relative;
    min-height: 80vh;
    width: 100%;
    background-image: linear-gradient(200deg, rgba(88,10,177,0.8) 30%, rgba(56,56,56,0) 70%), url(${homebg});
    background-size: cover, 120%;
    background-attachment: scroll, fixed;
    background-position: bottom, top center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    gap: 10px;
    transition: all 500ms ease-out;
`;

const FooterText = styled.p`
    font-family: "Poppins-ExtraLight";
    font-size: 3rem;
    letter-spacing: -0.04rem;
    color: #fff;
    width: fit-content;
    line-height: 110%;
    margin-bottom: 5px;

    span {
    font-family: "Poppins-Medium";
    color: #FEDA00;
    }
`;

const FooterTextSmall = styled.p`
    font-family: "Acumin-Thin";
    font-size: 0.85rem;
    line-height: 180%;
    max-width: 300px;
    color: #ffffff;
    letter-spacing: 0.03rem;
`;

const FooterTextContainer = styled.div`
    width: 99vw;
    max-width: 880px;
    height: fit-content;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    justify-items: center;
    align-items: left;
    background-image: linear-gradient(300deg, rgba(56,56,56,0) 0%, rgba(44,44,44,1) 80%), url(${blackbg});
    padding: 64px 30px;
    transition: all 500ms ease-out;

    @media (min-width: 800px) {
      flex-direction: row;
    }
`; 

const MessageFormText = styled.div`
    overflow: hidden;
    padding: 20px;
`; 

const RocketManIMG = styled.img`
    width: 150px;
    height: auto;
    display: none;
    position: absolute;
    Top: 70px;
    left: 780px;


    @media (min-width: 900px) {
      display: block;
    }
`;

const RocketManImage: React.FC = () => {

    const animationRef = useRef<GSAPTween | null>(null);
    let spacemanRef = useRef<HTMLImageElement>(null);

    useEffect(() => {
        if (spacemanRef.current && !animationRef.current) {
          animationRef.current = gsap.to(spacemanRef.current, {
            rotation: '4',
            y: "-30", // Move up
            duration: 5, // Slow hovering effect
            yoyo: true, // Reverse animation
            repeat: -1, // Infinite loop
            ease: "sine.inOut", // Smooth easing
          });
        }
      }, []);

    return (
    <RocketManIMG src={rocketman} alt="rocketman" ref={spacemanRef}/>
    );
};


const HomeContact: React.FC = () => {

    const { contactRef } = useScroll();

    const [messageStatus, setMessageStatus] = useState<boolean | null>(null);

    const handleMessageSuccess = () => {
        setMessageStatus(true);
        localStorage.setItem('messageSent', 'true');
      };

      useEffect(() => {
        if (localStorage.getItem('messageSent')) {
            setMessageStatus(true);
        }
      });

    return (
        <HomeContactContainer ref={contactRef} id="contact">
            <FooterTextContainer>
            { messageStatus ? ('') : (<MessageForm onMessageSuccess={handleMessageSuccess} />)}
                <MessageFormText>
                { messageStatus ? (
                    <>
                    <FooterText><span>Thank You!</span></FooterText>
                    <FooterTextSmall>Your message has been received!</FooterTextSmall>
                    </>
                ) : (
                    <>
                    <FooterText>Have an <span>opportunity</span> for me?</FooterText>
                    <FooterTextSmall>Have a job or project for me? Send me a quick message!</FooterTextSmall>
                    </>
                )}
                </MessageFormText>
                { messageStatus ? (null) : (<RocketManImage />)}
                
            </FooterTextContainer>
        </HomeContactContainer>
    );

};

export default HomeContact;
import React, { useState } from 'react';

import styled from 'styled-components';

import Loading from '../components/loading/Loading';

import HomeHead from '../content/home/HomeHead';
import HomeAbout from '../content/home/HomeAbout';
import DesignPortfolio from '../content/design/DesignPortfolio';
import DevPortfolio from '../content/dev/DevPortfolio';
import HomeContact from '../content/home/HomeContact';


const HomeContainer = styled.section`
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
    z-index: 0;
`;

const Home: React.FC = () => {

    const [assetsLoaded, setAssetsLoaded] = useState(false)

    const assetsLoadedCallback = () => {
        const timeout = setTimeout(() => {
            setAssetsLoaded(true);
        }, 500);

        return () => clearTimeout(timeout)
    }

    return (
        <>
            <Loading visible={!assetsLoaded} />
            <HomeContainer>  
                <HomeHead onAssetsLoaded={assetsLoadedCallback}></HomeHead>
                <HomeAbout />
                <DesignPortfolio />    
                <DevPortfolio />
                <HomeContact />
            </HomeContainer>
        </>
    );
};

export default Home;
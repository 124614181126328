import React, {useState, MouseEvent} from 'react';
import styled from 'styled-components';

type LinkButtonProps = {
    children: React.ReactNode;
    $externalUrl?: string;
    onClick?: () => void;
}

const StyledItemButton = styled.button`
  position: relative;
  font-size: 1em;
  font-family: 'Rubik';
  color: #ffffff;
  padding: 12px 0px;
  border: none;
  background: #353535;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  border: 2px solid #00B0D3;
  border-radius: 50px;
  width: 200px;
  overflow: hidden;
  align-self: end;

  &:hover {
    color: #fff;
  }
`;


const DevItemButton: React.FC<LinkButtonProps> = ({ $externalUrl, children }) => {
    const [isTracking, setIsTracking] = useState(false);
    const [circlePosition, setCirclePosition] = useState({ x: 0, y: 0 });

      const handleMouseEnter = () => {
        setIsTracking(true);
    };

    const handleMouseLeave = () => {
        setIsTracking(false);
    };

    const handleMouseMove = (e: MouseEvent) => {
        if (isTracking) {
            const buttonRect = e.currentTarget.getBoundingClientRect();
            const x = e.clientX - buttonRect.left;
            const y = e.clientY - buttonRect.top;
            setCirclePosition({ x, y });
        }
    };

    const handleOpenURL = () => {
        if ($externalUrl) {
            window.open($externalUrl, '_blank');
        }
    };

    return (
        <StyledItemButton 
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseMove={handleMouseMove}
        style={{ position: 'relative' }}
        onClick={handleOpenURL}>
            <div style={{position: 'relative', zIndex: '1'}}>
                {children}
            </div>
        {isTracking && (
        <div
            style={{
                position: 'absolute',
                top: circlePosition.y,
                left: circlePosition.x,
                width: '220px',
                height: '220px',
                backgroundColor: '#565656',
                borderRadius: '50%',
                transform: 'scale(0) translate(-50%, -50%)',
                zIndex: '0',
                animation: 'growCircle 500ms ease-in-out forwards',
            }}
        />
      )}
        </StyledItemButton>
    );
};

export default DevItemButton;
import React, { useState, useEffect, useRef } from "react";
import styled from 'styled-components';
import {gsap } from "gsap";
import { useNavigate, useLocation } from 'react-router-dom'; 

import { useScroll } from "../../../context/scrolltocontext";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faCode, faPaperPlane } from '@fortawesome/free-solid-svg-icons';

import markLogoImg from '../../assets/images/mark-logo.svg';


type MobileMenuProps = {
    $showMenu: boolean;
    menuButtonClick: () => void;
}

type MenuIconProps = {
    $showMenu: boolean;
    onClick: () => void;
}

type MobileMenuButtonProps = {
    onClick: () => void;
}

type MenuIconStyleProps = {
    $showMenu: boolean;
}

type MenuContainerProps = {
    $isScrolled: boolean;
}

const MenuContainer = styled.nav<MenuContainerProps>`
    position: fixed;
    z-index: 10;
    height: ${(props) => (props.$isScrolled ? '40px' : '100px')};
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => (props.$isScrolled ? '#440097' : 'none')};
    transition: all 0.2s ease;
`;

const MenuContainerInner = styled.div`
    width: 80%;
    max-width: 2000px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const NameLogo = styled.span<MenuContainerProps>`
    justify-self: start;
    font-family: Rubik;
    font-size: 1rem;
    text-transform: uppercase;
    cursor: pointer;
    transition: all .2s ease;

    @media (min-width: 800px) {
        font-size: ${(props) => (props.$isScrolled ? '1rem' : '1.5rem')};
     }
`;

const MobileMenuContainer = styled.div<MobileMenuProps>`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 9;
    align-items: center;
    top: ${(props) => (props.$showMenu ? '0' : '100%')};
    background: radial-gradient(circle at 30% -30%, #440097 2%, rgba(48,10,92,1) 51%, rgba(48,10,92,0.5) 95%);
    background-repeat: no-repeat;
    background-position: 100% 100%;
    backdrop-filter: blur(45px);
    background-size: 100%;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    padding: 50px;
    visibility: ${(props) => (props.$showMenu ? 'visible' : 'hidden')};
    transition: all 300ms ease-in-out;

    &::-webkit-scrollbar {
        width: .15rem;  /* Adjust as needed */
    }

    &::-webkit-scrollbar-thumb {
        background-color: #bb00ff;  /* Color of the thumb */
        border-radius: 0.5rem;  /* Rounded corners */
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: #59ff00;  /* Color on hover */
    }

    &.fader-enter {
    opacity: 0;
    }

    &.fader-enter-active {
        opacity: 1;
        transition: all 300ms;
    }

    &.fader-exit {
        opacity: 1;
    }

    &.fader-exit-active {
        opacity: 0;
        transition: all 300ms;
    }

`;

const MobileMenuContent = styled.div`
    box-sizing: border-box;
    position: relative;
    top: 40px;    
    width: 85%;
    max-width: 2000px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding-bottom: 100px;
    opacity: 1;
    user-select: none;

    &.faderr-enter {
        transform: translateY(100px);
    }

    &.faderr-enter-active {
        transform: translateY(0px);
        transition: all 300ms;

    }

    &.faderr-exit {
        transform: translateY(0px);
    }

    &.faderr-exit-active {
        transform: translateY(100px);
        transition: all 300ms;
    }
`;

const MarkLogo = styled.img`
    display: block;
    height: 180px;
    width: auto;
    align-self: flex-start;
    margin-bottom: 20px;
`;

const MenuOption = styled.span<MobileMenuButtonProps>`
    display: none;
    font-family: 'Rubik';
    font-size: 1rem;
    text-transform: uppercase;
    cursor: pointer;
    transition: all .2s ease;

    @media (min-width: 800px) {
        display: inline-block;
     }

    &.current {
        color: #FFD900;
    }
    &.current:hover {
        color: #FFD900;
    }
    &:hover {
        color: #FFD900;
     }
`;

const MenuOptionsContainer = styled.div`
    display: flex;
    align-self: flex-end;
    align-items: center;
    gap: 1vw;
`;

const MobileMenuOption = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;

    &:nth-of-type(3) {
        flex-direction: column;
        gap: 0px;
        margin-top: 20px;
        width: fit-content;

        @media (min-width: 600px) {
            flex-direction: row;
            gap: 40px;
        }

    }
`;

const MobileMenuButton = styled.h2<MobileMenuButtonProps>`
    font-family: 'Oswald';
    font-size: 2.75rem;
    cursor: pointer;
    transform: rotate(-2deg);
    transform-origin: left;
    width: fit-content;

    &.current {
        color: #7300ff;
    }
    &.current:hover {
        color: #7300ff;
    }

    &.current::after {
    content: "+HERE"; 
    position: absolute;
    top: 45px; 
    right: -35px; 
    color: #9dffdb; 
    font-size: 1rem; 
  }

  &:hover {
        color: #00ffa6;
  }
`;

const MobileMenuParagraph = styled.p`
    font-family: 'Rubik';
    font-weight: 400;
    width: 100%;
    line-height: 160%;
    font-size: 1rem;
    overflow: hidden;
    transition: height .5s ease-in-out;
    color: #bebebe;
    padding-bottom: 30px;
    max-width: 700px;
`;

const LargeAwesomeIcon = styled(FontAwesomeIcon)<MenuIconStyleProps>`
    font-size: 1.25rem;
    transition: all 0.3s ease-in-out;
    transform: ${(props) => (props.$showMenu ? 'rotate(90deg)' : 'rotate(0deg)')};
    background-color: ${(props) => (props.$showMenu ? '#ffffff14' : 'rgb(255,255,255,0)')}; ;
    border-radius: 100px;
    padding: .35rem .5rem;

    cursor: pointer;

    &:hover {
        background-color: #ffffff14;
    }
`;

const MenuIcon = React.forwardRef<SVGSVGElement, MenuIconProps>(({ $showMenu, onClick }, ref) => {

    const icon = $showMenu ? faTimes : faBars;

    return (
        <LargeAwesomeIcon icon={icon} onClick={onClick} $showMenu={$showMenu} ref={ref}/>
    );
});

const MobileMenu = React.forwardRef<HTMLDivElement, MobileMenuProps>(({ $showMenu, menuButtonClick }, ref) => {

    const { activeSection, designPortfolioRef, devPortfolioRef, scrollToSection } = useScroll();


    // const navigate = useNavigate();

    // const loadPortfolio = (route: string) => {
    //     if (location.pathname !== route) {navigate(route)};
    // };

    return (
        <MobileMenuContainer $showMenu={$showMenu} ref={ref} menuButtonClick={menuButtonClick}>
                <MobileMenuContent>  
                    <MobileMenuOption>
                    <MobileMenuButton
                        className={activeSection === 'design' ? 'current' : ''}
                        onClick={() => {scrollToSection(designPortfolioRef); menuButtonClick();}}
                    >
                        <FontAwesomeIcon icon={faPaperPlane} />
                        DESIGN
                    </MobileMenuButton>
                    <MobileMenuParagraph>
                        With my years of graphic design experience I've got you covered for all your business marketing needs.
                    </MobileMenuParagraph>
                    </MobileMenuOption>  
                    <MobileMenuOption>  
                    <MobileMenuButton
                        className={activeSection === 'dev' ? 'current' : ''}
                        onClick={() => {scrollToSection(devPortfolioRef); menuButtonClick();}}
                    >
                        <FontAwesomeIcon icon={faCode} />
                        DEVELOPMENT
                    </MobileMenuButton>
                    <MobileMenuParagraph>
                        I've got a solid background in the fundamentals of frontend web development, with hands-on experience in building web applications from start to finish.</MobileMenuParagraph>
                    </MobileMenuOption>  
                    <MobileMenuOption>  
                    <MarkLogo src={markLogoImg}/>                   
                    <MobileMenuParagraph style={{paddingTop: 8, fontSize: 15}}>
                    40, living in Barrie, Ontario, I have many years of experience related to computers, marketing, graphic design, and web development. Recently I graduated from Georgian College, on the Dean's List, and have spent the last 4 years learning the fundamentals of web development. 
                    <br /><br />
                    The majority of my experience comes from designing everything related to marketing a business such as mailout flyers, websites, print ads, logos, merchandise, and more.
                    <br /><br />
                    Hire Me! I am currently seeking a full-time opportunity related to graphic design for marketing and/or web development.
                    </MobileMenuParagraph>
                    </MobileMenuOption>  
                </MobileMenuContent>
        </MobileMenuContainer>
    );
});

const Menu = () => {
   
    const { activeSection, aboutRef, designPortfolioRef, devPortfolioRef, homeRef, scrollToSection } = useScroll();

    const [showMenu, setShowMenu] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    const menuRef = useRef<HTMLDivElement | null>(null);
    const menuButtonRef = useRef<SVGSVGElement | null>(null);

    const boxRef = useRef<HTMLDivElement | null>(null)

    const menuItemRef = useRef<(HTMLSpanElement | null)[]>([]); // Array of refs for menu items
;
    // const goHome = (route: string) => {
    //     navigate(route);
    // };

    const addToRefs = (el: HTMLSpanElement | null) => {
        if (el && !menuItemRef.current.includes(el)) {
          menuItemRef.current.push(el);
        }
    };

    // const loadPortfolio = (route: string) => {
    //     if (location.pathname !== route) {navigate(route)};
    // };

    const menuButtonClick = () => {
        if (showMenu) {
            setShowMenu(false)
            document.body.style.overflow = 'auto';
        } else {
            setShowMenu(true)
            document.body.style.overflow = 'hidden';
        };
    };

    const handleOutsideClick = (event: MouseEvent) => {
        if (menuRef.current && event.target instanceof Node){
            if ((showMenu && (!menuButtonRef.current || !menuButtonRef.current.contains(event.target)) && !menuRef.current.contains(event.target))) {
                menuButtonClick();
            };
        };  
    };

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    });

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 70) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        // GSAP animation
        gsap.fromTo(boxRef.current, {
          opacity: 1,
          y: -60,
        },{
          opacity: 1,
          y: 0,
          delay: 0.25,
          duration: .05});
        }, [isScrolled]);

        useEffect(() => {
            // Filter out null values before passing to GSAP
            const elements = menuItemRef.current.filter((el): el is HTMLSpanElement => el !== null);
        
            gsap.to(elements, {
              opacity: 1,
              x: -40,
              duration: 0.5,
              ease: "power2.out",
              delay: .25,
              stagger: 0.25, // Delays each item's animation by 0.2 seconds
            });
          }, []);

    return (
        <>
            <MenuContainer $isScrolled={isScrolled} ref={boxRef}>
                <MenuContainerInner>
                    <NameLogo onClick={() => scrollToSection(homeRef)} $isScrolled={isScrolled} >Mark McGuigan<wbr/> {activeSection === 'dev' ? ': DEV' : activeSection === 'design' ? ': DESIGN' : activeSection === 'about' ? ': ABOUT' : activeSection === 'contact' ? ': CONTACT' : ''}</NameLogo>
                    <MenuOptionsContainer>
                        <MenuOption
                        className={activeSection === 'design' ? 'current' : ''}
                        onClick={() => scrollToSection(designPortfolioRef)}
                        ref={addToRefs}
                        >design</MenuOption>
                        <MenuOption
                        className={activeSection === 'dev' ? 'current' : ''}
                        onClick={() => scrollToSection(devPortfolioRef)}
                        ref={addToRefs}
                        >dev</MenuOption>
                        <MenuOption
                        className={activeSection === 'about' ? 'current' : ''}
                        onClick={() => scrollToSection(aboutRef)}
                        ref={addToRefs}
                        >about</MenuOption>
                        <MenuIcon $showMenu={showMenu} onClick={menuButtonClick} ref={menuButtonRef}/>
                    </MenuOptionsContainer>
                </MenuContainerInner>
            </MenuContainer>
            <MobileMenu $showMenu={showMenu} menuButtonClick={menuButtonClick} ref={menuRef} />
        </>
    );
}

export default Menu;
import React, { useLayoutEffect, useRef, useState, useEffect } from "react";
import styled from "styled-components";

import { gsap } from "gsap";
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { useResize } from '../../context/resizecontext';

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);


interface GaugeProps {
  value: number; // Value for the gauge (0-100)
  max?: number; // Maximum value (default: 100)
  size?: number; // Diameter of the gauge (default: 200px)
  name?: string; // Gauge name
  strokeWidth?: number; // Thickness of the arc (default: 10px)
  color?: string; // Color of the foreground arc
  backgroundColor?: string; // Color of the background arc
}

const GaugeContainer = styled.div<{ size: number }>`
  position: relative;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px; 
  overflow: hidden;
`;

const GaugeSvg = styled.svg<{ size: number }>`
  width: 100%;
  height: 100%;
`;

const Circle = styled.circle<{ strokeWidth: number; color: string }>`
  fill: #FEDA0040;
  stroke-width: '${(props) => props.strokeWidth}';
  stroke-linecap: round;
`;

const Text = styled.text`
  font-family: 'Oswald';
  font-size: 1.75rem;
  font-weight: bold;
  fill: #333333;
  text-anchor: middle;
  dominant-baseline: middle;
`;

const TextSmall = styled.text`
  font-family: 'Oswald';
  font-size: 0.75rem;
  font-weight: bold;
  fill: #3b3b3b;
  text-anchor: middle;
  dominant-baseline: middle;
  text-transform: uppercase;
`;

const GaugesContainer = styled.div`
    box-sizing: border-box;
    width: fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: clamp(20px, 4vw, 76px);
`

const GaugesContainerOutter = styled.div`
    box-sizing: border-box;
    width: 100%;
    overflow-x: hidden;
    max-width: 1500px;
  
    border: 0px solid #7500FF;
    padding: 24px 15px;
    border-radius: 150px;

`

const Gauge: React.FC<GaugeProps> = ({
  value,
  max = 100,
  size = 180,
  strokeWidth = 11,
  color = "#F200ff",
  name = "Gauge",
  backgroundColor = "#5f00d2",
}) => {
  const radius = (size - strokeWidth) / 2; // Adjust for stroke width
  const circumference = 2 * Math.PI * radius;
  const clampedValue = Math.min(Math.max(value, 0), max); // Clamp value between 0 and max
  const offset = circumference - (clampedValue / max) * circumference;

  const circleRef = useRef<SVGCircleElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [displayValue, setDisplayValue] = useState(0); // State for animated value

  gsap.registerPlugin(useGSAP);
  gsap.registerPlugin(ScrollTrigger);

  useLayoutEffect(() => {
    const timeout = setTimeout(() => {

    if (!circleRef.current || !containerRef.current) return;
  
    const circleAnimation = gsap.fromTo(
      circleRef.current,
      { strokeDashoffset: circumference },
      {
        strokeDashoffset: offset,
        duration: 2,
        ease: "power1.out",
        paused: true,
      }
    );
  
    const valueAnimation = gsap.to(
      { value: 0 },
      {
        value: clampedValue,
        duration: 2,
        ease: "power1.out",
        paused: true,
        onUpdate: function () {
          setDisplayValue(Math.round(this.targets()[0].value));
        },
      }
    );
  
    const trigger = ScrollTrigger.create({
      trigger: containerRef.current,
      start: "top 100%",
      end: "bottom 0%",
      toggleActions: "play none none none",
      onEnter: () => {
        circleAnimation.restart();
        valueAnimation.restart();
      },
    });

    trigger.refresh();

    return () => {
      clearTimeout(timeout);
      trigger.kill(); // Clean up ScrollTrigger
      circleAnimation.kill(); // Clean up GSAP animations
      valueAnimation.kill();
    };

  }, 1000);
  
  }, [circumference, offset, clampedValue]);

  return (
    <GaugeContainer size={size} ref={containerRef}>
      <GaugeSvg size={size} viewBox={`0 0 ${size} ${size}`}>
      <defs>
        <linearGradient id="gradient" x1="10%0" y1="0%" x2="0%" y2="50%">
          <stop offset="0%" stopColor={color} />
          <stop offset="100%" stopColor={backgroundColor} />
        </linearGradient>
      </defs>
        {/* Background */}
        <Circle
          r={radius}
          cx={size / 2}
          cy={size / 2}
          strokeWidth={strokeWidth/4}
          color={backgroundColor}
          stroke="#ffffff80"
        />
        {/* Foreground */}
        <Circle
          r={radius}
          cx={size / 2}
          cy={size / 2}
          stroke="url(#gradient)"
          strokeWidth={strokeWidth}   
          ref={circleRef}  
          color=""    
          style={{
            strokeDasharray: circumference,
            strokeDashoffset: circumference,
          }}
        />
        {/* Display Percentage */}
        <TextSmall x="52%" y="65%">
          {name}
        </TextSmall>
        <Text x="52%" y="50%">
          {Math.round((displayValue / max) * 100)}%
        </Text>
      </GaugeSvg>
    </GaugeContainer>
  );
};


const Gauges: React.FC = () => {

  const { width, height } = useResize();
    
    const wrapperRef = useRef<HTMLDivElement>(null);
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const animationRef = useRef<GSAPTween | null>(null);

    useEffect(() => {
        if (!wrapperRef.current || !scrollContainerRef.current) return;
    
        const wrapperWidth = wrapperRef.current.offsetWidth;
        const scrollableWidth = scrollContainerRef.current.scrollWidth;

            // Kill the previous animation if it exists
        if (animationRef.current) {
          animationRef.current.kill();
        }

        gsap.set(scrollContainerRef.current, { x: 0 });

        animationRef.current = gsap.to(scrollContainerRef.current, {
          x: wrapperWidth - scrollableWidth, // Move content to the left
          duration: 30, // Control the speed of movement
          ease: "linear",
          repeat: -1, // Infinite looping
          yoyo: true,
          modifiers: {
            x: (x) => {
              const value = parseFloat(x);
              return value < 0 ? `${value}px` : "0px"; // Only allow negative values
            },
          },
        });


      return () => {
        // Cleanup Draggable and animation
        if (animationRef.current) animationRef.current.kill();
      };
    }, [width, height]);

  return (
    <GaugesContainerOutter ref={wrapperRef}>
    <GaugesContainer ref={scrollContainerRef}>
            <Gauge value={90} name={"print design"}/>
            <Gauge value={90} name={"web design"}/>
            <Gauge value={90} name={"adobe cc"}/>
            <Gauge value={65} name={"development"}/>
            <Gauge value={65} name={"wordpress"}/>
            <Gauge value={65} name={"react"}/>
    </GaugesContainer>
    </GaugesContainerOutter>
  );

};

export default Gauges;
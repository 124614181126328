import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import styled from 'styled-components';

import { gsap } from 'gsap';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';


import Rocket from './rocket';

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);


const HomeHeroContainer = styled.div`
    position: relative;
    z-index: 0;
    width: 100%;
    max-width: 900px;

    @media (min-width: 800px) {
        display: block;
    }
`;

interface HomeHeroProps {
    onImagesLoaded: () => void;
}

const HomeHero: React.FC<HomeHeroProps> = ( { onImagesLoaded }) => {
    const [homeHeroLoaded, setHomeHeroLoaded] = useState(true);
    const homeHeroRef = useRef<SVGSVGElement | null>(null);
    const homeHeroTimelineRef = useRef<gsap.core.Timeline | null>(null);

    useLayoutEffect(() => {
        if (homeHeroRef.current) {
            homeHeroTimelineRef.current = gsap.timeline({ paused: true });
            homeHeroTimelineRef.current.to([homeHeroRef.current], {
            scale: 0.95,
            duration: 3.5,
            delay: 4,
          });
    }}, []);
    
    useEffect(() => {
        if (homeHeroLoaded && homeHeroTimelineRef.current) {
            onImagesLoaded();
            homeHeroTimelineRef.current.play();
        };
    }, [homeHeroLoaded, onImagesLoaded]);

    return (     
        <HomeHeroContainer> 
            <section>
                <Rocket ref={homeHeroRef} />
            </section> 
        </HomeHeroContainer>      
    )};

export default HomeHero;
import React, { useRef, ReactNode } from 'react';
import { useNavigate } from "react-router";

import { gsap } from 'gsap';
import { useGSAP } from '@gsap/react';

import styled from 'styled-components';

gsap.registerPlugin(useGSAP);

interface OptionsButtonProps {
    children?: ReactNode;
    onClick?: () => void;
}


const LearnMoreButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
`;

const LearnMoreButtonText = styled.span`
  color: #FFD200;
  font-family: 'Arial-MT-Bold';
  font-size: clamp(1rem, 1vw, 1.25rem);
  margin-left: 10px;
  text-transform: uppercase;
`;

const CircleContainer = styled.div`
  height: 30PX;
  width: 30PX;
  transform: rotate(90deg);
`;

const CircleWrapper = styled.div`
  width: 100%;
  height: auto;
  cursor: pointer;

  svg {
    width: 100%;
    height: auto;
  }
`;

const LinkHere: React.FC<OptionsButtonProps> = ({ children, onClick}) => {


    const circleRef = useRef<SVGCircleElement | null>(null);

    const handleMouseEnter = () => {
      gsap.to(circleRef.current, {
        strokeDashoffset: 0, // Animate to full stroke
        duration: 1, // 1 second animation
        ease: "power1.out",
        overwrite: "auto"
      });
    };
  
    const handleMouseLeave = () => {
      gsap.to(circleRef.current, {
        strokeDashoffset: 440, // Reset stroke
        duration: 0.5, // Faster reset
        ease: "power1.in",
        overwrite: "auto"
      });
    };

    return (
        <LearnMoreButton
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={onClick}
            >
            <CircleContainer
                      >
            <CircleWrapper>
              <svg viewBox="0 0 150 150">
                <circle
                  cx="75"
                  cy="75"
                  r="70"
                  fill="#FFFFFA05"
                  stroke="#FFFFFA55"
                  strokeWidth="10"
                  strokeDasharray="440" // Circumference of the circle
                  strokeDashoffset="0" // Hidden initially
                />
                                <circle
                  cx="75"
                  cy="75"
                  r="70"
                  fill="none"
                  stroke="#FFD200"
                  strokeWidth="10"
                  strokeDasharray="440" // Circumference of the circle
                  strokeDashoffset="440" // Hidden initially
                  ref={circleRef}
                />
                <polygon
                  points="55,55 97,75 55,95"
                  fill="#FFD200" // Arrow color
                />
              </svg>
            </CircleWrapper>
          </CircleContainer>
              <LearnMoreButtonText>
                  {children}
              </LearnMoreButtonText>
            </LearnMoreButton>
    );

};


export default LinkHere;
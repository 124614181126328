import React, {useState, useEffect, useMemo} from "react";
import styled from "styled-components";

import { useScroll } from '../../../context/scrolltocontext';

import HomeHero from './HomeHeadHero';
import HomeText from './HomeHeadText';

import homebg from '../../assets/images/bg.webp';

import Particles, { initParticlesEngine } from '@tsparticles/react';
import { type Container, type ISourceOptions, MoveDirection,  OutMode } from "@tsparticles/engine";
import { loadSlim } from "@tsparticles/slim"; 

interface HomeHeadProps {
    onAssetsLoaded: () => void;
}

const HomeHead = styled.div`
    width: 100vw;
    min-height: 50vh;
    background-image: linear-gradient(200deg, rgba(76,37,155,0.7) 10%, rgba(56,56,56,0) 50%), url(${homebg});
    background-repeat: no-repeat;
    background-attachment: fixed, fixed;
    background-position: top, left center;
    background-size: cover, cover;
    display: flex; 
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    overflow-x: hidden;
    padding-bottom: 250px;

     @media (min-width: 1100px) {
      min-height: 58vh;
      padding-bottom: 150px
    }

    @media (min-width: 1920px) {
      min-height: 85vh;
      max-height: 1500px;
      background-position: center, center right;
      background-attachment: fixed, fixed;
      background-size: cover, 130%;
    }
    
`;

const Inner = styled.section`
    width: 80%;
    height: fit-content;
    max-width: 1800px;
    display: flex; 
    flex-direction: column-reverse; 
    margin-top: 14vh;

    @media (min-width: 1100px) {
      flex-direction: row;
      // align-items: center;
      // margin-top: 12vh;
    }

    @media (min-width: 2000px) {
      // margin-top: 14vh;
    }
`;


const HomeHeader: React.FC<HomeHeadProps> = ( { onAssetsLoaded }) => {
    const [imagesLoaded, setImagesLoaded] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const [init, setInit] = useState<boolean>(false);

    const { homeRef } = useScroll();

  useEffect(() => {
      initParticlesEngine(async (engine) => {
        await loadSlim(engine);
          }).then(() => {
          setInit(true);
          });
  }, []);
  
  const particlesLoaded = async (container?: Container): Promise<void> => {
    // console.log(container);
  };

  const options: ISourceOptions = useMemo(
    () => ({
      fpsLimit: 60,
      particles: {
        color: {
          value: ["#ffffff"]
        },
        move: {
          direction: MoveDirection.none,
          enable: true,
          outModes: {
            default: OutMode.bounce,
          },
          random: false,
          speed: 0.15,
          straight: false,
        },
        number: {
          density: {
            enable: true,
          },
          value: 30,
        },
        blur: {
            radius: 5, 
            enabled: true,
          },
        opacity: {
            value: {
              min: 0,
              max: 0.9
            }
          },
        rotate: {
        value: { min: 0, max: 1260 }, 
        animation: {
            enable: true, 
            speed: 1, 
            sync: false, 
        },
        direction: "clockwise", 
        path: true, 
        },
        shape: {
          type: "triangle",
        },
        size: {
            value:  {min: 1, max: 15}, 
            random: true, 
            animation: {
                enable: true,
                speed: 0.2, 
                size_min: 1, 
                sync: false, 
            },
        },
      },
      detectRetina: true,
    }),
    []
  );
    
    const imagesLoadedCallback = () => {
        setImagesLoaded(true);
    }

    const fontsLoadedCallback = () => {
        setFontsLoaded(true);
    }

    useEffect(() => {
        if (imagesLoaded && fontsLoaded){
            onAssetsLoaded();
        }
    }, [imagesLoaded, fontsLoaded, onAssetsLoaded]);


    return (
        <HomeHead ref={homeRef} id="home">
        {init && (
            <Particles
                id="tsparticles"
                particlesLoaded={particlesLoaded}
                options={options}
            />
        )}
            <Inner>
                <HomeText onFontsLoaded={fontsLoadedCallback}></HomeText>
                <HomeHero onImagesLoaded={imagesLoadedCallback}></HomeHero>  
            </Inner>
        </HomeHead>  
    );
}

export default HomeHeader
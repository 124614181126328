import React, { createContext, useRef, useContext, useEffect, useState } from 'react';

// Define the type of the context
type ScrollContextType = {
  activeSection: string;
  aboutRef: React.RefObject<HTMLDivElement>;
  designPortfolioRef: React.RefObject<HTMLDivElement>;
  devPortfolioRef: React.RefObject<HTMLDivElement>;
  homeRef: React.RefObject<HTMLDivElement>;
  contactRef: React.RefObject<HTMLDivElement>;
  scrollToSection: (sectionRef: React.RefObject<HTMLDivElement>) => void;
};

// Create the context
const ScrollContext = createContext<ScrollContextType | undefined>(undefined);

// Combine Context and Provider
export const ScrollProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // Create refs for different sections
  const aboutRef = useRef<HTMLDivElement>(null);
  const designPortfolioRef = useRef<HTMLDivElement>(null);
  const devPortfolioRef = useRef<HTMLDivElement>(null);
  const homeRef = useRef<HTMLDivElement>(null);
  const contactRef = useRef<HTMLDivElement>(null);

  const [activeSection, setActiveSection] = useState<string>('home');

  // Function to scroll to a specific section
  const scrollToSection = (sectionRef: React.RefObject<HTMLDivElement>) => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

    // Detect which section is in view using IntersectionObserver
    useEffect(() => {
        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                setActiveSection(entry.target.id); // Update active section
              }
            });
          },
          { threshold: 0.15 } // Trigger when 50% of the element is in view
        );
    
        // Observe all sections
        if (homeRef.current) observer.observe(homeRef.current);
        if (aboutRef.current) observer.observe(aboutRef.current);
        if (designPortfolioRef.current) observer.observe(designPortfolioRef.current);
        if (devPortfolioRef.current) observer.observe(devPortfolioRef.current);
        if (contactRef.current) observer.observe(contactRef.current);
    
        // Cleanup observer on unmount
        return () => {
          observer.disconnect();
        };
      }, []);

  return (
    <ScrollContext.Provider value={{ activeSection, aboutRef, designPortfolioRef, devPortfolioRef, homeRef, contactRef, scrollToSection }}>
      {children}
    </ScrollContext.Provider>
  );
};

// Custom hook for consuming the context
export const useScroll = (): ScrollContextType => {
  const context = useContext(ScrollContext);
  if (!context) {
    throw new Error('useScroll must be used within a ScrollProvider');
  }
  return context;
};

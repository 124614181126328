import React, { useEffect, useState, createContext, useContext, useRef } from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSpinner, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

import { DesignItem } from '../../../types/types';

interface LightboxContextType {
    openLightbox: (data: DesignItem[], index: number) => void;
  }


type LightboxHeaderProps = {
    $imageTitle: string;
    children: React.ReactNode;
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

type LightboxImageProps = {
    src: string;
    onClick?: (e: React.MouseEvent<HTMLImageElement>) => void;
};

type LightboxImageContainerProps = {
    ref?: React.Ref<HTMLDivElement>;
}

const LightboxModal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    transform: scale(150%);
    opacity: 0;
    min-height: 100vh;
    background: #171717ae;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 35000;
    cursor: pointer;
    transition: all .2s;
    animation: LightboxPop .25s 1 forwards;
    backdrop-filter: blur(200px);

    @keyframes LightboxPop {
        100% {
            opacity: 1;
            transform: scale(100%);
        }
    }
`;

const LightboxHeaderContent = styled.div<LightboxHeaderProps>`
    position: absolute;
    height: 40px;
    top: 0;
    width: 100%;
    max-width: 2560px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    border-bottom: 1px solid #ffffff3e;

    @media (min-width: 600px) {
        padding: 0px 0px 0px 20px;
    }
`;

const LightboxHeaderTitle = styled.p`
    font-family: 'Arial-MT-Bold';
    font-size: 12px;
    text-transform: uppercase;
    color: #ffffff;
    margin-left: 5px;

`;

const LightboxImageContainer = styled.div<LightboxImageContainerProps>`
    box-sizing: border-box;
    overflow-y: auto;
    max-width: 100%;
    margin-top: 50px;
    margin-bottom: 20px;
    
    &::-webkit-scrollbar {
        background-color: #000000;
        width: 10px;
    }
    &::-webkit-scrollbar-track {
        background-color: #242424;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #5e0cc3;
        border-radius: 6px;
        height: 10px;
    }

    @media (min-width: 800px) {
        max-width: 80%;
    };
`;

const LightboxImage = styled.img<LightboxImageProps>`
    max-width: 100%;
`;

const ButtonsContainer = styled.div`
    display: flex;
    gap: 5px;
`;

const LightboxAwesomeIcon = styled(FontAwesomeIcon)`
    font-size: 30px;
    cursor: pointer;
    z-index: 1500;
`;

const LightboxButton = styled.div`
    display: flex;
    padding: 5px;
    color: #fff;

    &:nth-of-type(3)  {
        padding-left: 15px;
        padding-right: 15px;
        color: #fff;     
    }
`;

const LoadingIcon = styled(FontAwesomeIcon)`
    position: fixed;
    top: 50%;
    right: 50%;
    font-size: 3vw;
    z-index: 1500;
    animation: loadingRotate 2s infinite;
    color: #fff;

    @keyframes loadingRotate {
        0% {
            transform: rotate(0turn);
        }
        100% {
            transform: rotate(-1turn);
        }
    }
`;

const LightboxContext = createContext<LightboxContextType | undefined>(undefined);

export const LightboxProvider: React.FC<{children: React.ReactNode}> = ({ children }) => {

    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [lightboxImageUrl, setLightboxImageUrl] = useState('');
    const [imageTitle, setImageTitle] = useState('');
    const [currentLightboxIndex, setCurrentLightboxIndex] = useState(0);
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [lightboxData, setLightboxData] = useState<DesignItem[] | null>(null);
    const lightboxRef = useRef<HTMLDivElement | null>(null);
    const image = new Image();
    
    useEffect(() => {
        scrollToTop();
      }, [lightboxOpen]);

    const closeLightbox = () => {
        document.body.style.overflow = 'auto';
        image.src = '';
        setIsImageLoaded(false);
        setLightboxImageUrl('');
        setLightboxOpen(false);
        setLightboxData(null);
    }

    const scrollToTop = () => {
        if (lightboxRef.current) {
          lightboxRef.current.scrollTop = 0;
        }
    };

    const imageClick = (e: React.MouseEvent<HTMLImageElement>) => {
        e.stopPropagation();
    };

    const headerClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
    };

    const openLightbox = (data: DesignItem[], index: number) => {
        setLightboxData(data);
        setCurrentLightboxIndex(index);
    }

    useEffect(() => {
        if (lightboxData){
            const imageUrl = lightboxData[currentLightboxIndex].full;
            const imageTitle = lightboxData[currentLightboxIndex].title;
            setLightboxOpen(true);    
            document.body.style.overflow = 'hidden';
            setImageTitle(imageTitle);
            setLightboxImageUrl(`/assets/images/${imageUrl}`);
        };
    }, [lightboxData, currentLightboxIndex]);

    useEffect(() => {
        if (lightboxImageUrl){
            image.src = lightboxImageUrl;
            image.onload = () => setIsImageLoaded(true);
        };
    }, [lightboxImageUrl]);

    const handleNext = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();

        if (lightboxData){
            const nextIndex = (currentLightboxIndex + 1) % lightboxData.length;
            setCurrentLightboxIndex(nextIndex);        

            const imageUrl = lightboxData[nextIndex].full;
            const imageTitle = lightboxData[nextIndex].title;

            setLightboxImageUrl(`/assets/images/${imageUrl}`);
            setImageTitle(imageTitle);
        }
    };
      
      const handlePrevious = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();

        if (lightboxData){
            const previousIndex = (currentLightboxIndex - 1 + lightboxData.length) % lightboxData.length;
            setCurrentLightboxIndex(previousIndex);

            
            const imageUrl = lightboxData[previousIndex].full;
            const imageTitle = lightboxData[previousIndex].title;

            setLightboxImageUrl(`/assets/images/${imageUrl}`);
            setImageTitle(imageTitle);
        }
    };

    return(
        <LightboxContext.Provider value={{ openLightbox }}>
        {children}
        {lightboxOpen && lightboxData && (
            <LightboxModal onClick={closeLightbox}>
                <LightboxHeaderContent $imageTitle={imageTitle} onClick={headerClick}>
                    <LightboxHeaderTitle>
                        {imageTitle}
                    </LightboxHeaderTitle>
                    <ButtonsContainer>
                        <LightboxButton onClick={(e) => {scrollToTop(); handlePrevious(e);}}><LightboxAwesomeIcon icon={faArrowLeft} /></LightboxButton>
                        <LightboxButton onClick={(e) => {scrollToTop(); handleNext(e);}}><LightboxAwesomeIcon icon={faArrowRight} /></LightboxButton>
                        <LightboxButton onClick={closeLightbox}><LightboxAwesomeIcon icon={faTimes} /></LightboxButton>
                    </ButtonsContainer>
                </LightboxHeaderContent>
                {isImageLoaded ? (
                    <LightboxImageContainer ref={lightboxRef}>
                        <LightboxImage 
                            onClick={imageClick}
                            src={lightboxImageUrl}
                        ></LightboxImage>
                    </LightboxImageContainer>
                ) : (
                <LoadingIcon icon={faSpinner}/>
                )}
            </LightboxModal>
        )}
        </LightboxContext.Provider>
    );
};


export const useLightbox = () => {
    const context = useContext(LightboxContext);
    if (!context) {
      throw new Error('useLightbox must be used within a LightboxProvider');
    }
    return context;
  };